<template>
  <div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      setHeight="500px"
      v-model="tab"
    />
    <br><br>
    <c-tab
      :tabItems="tabItems"
      v-model="tab"
    />
    <br><br>
    <c-tab
      type="tabcard"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      align="left"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
        />
      </template>
    </c-tab>
  </div>

</template>

<script>
export default {
  name: 'tab',
  props: {
    popupParam: {
      type: Object,
      default: () => ({

      }),
    },
  },
  data() {
    return {
      editable: true,
      splitter: 5,
      tab: 'checklist',
      tabItems: [
        { name: 'checklist', icon: 'checklist', label: 'base page 1', component: () => import(`${'./basePage.vue'}`), param: { a: 1, b: 'V' } },
        { name: 'request', icon: 'edit', label: 'base page 2', component: () => import(`${'./basePage.vue'}`), param: { a: 2, b: 'V' } },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
